<script>
/**
 * @see http://sian.ayos.co.kr/holdum/resources/holdum/room.html
 */

import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as gameSock from "@/gameSock";
import {debounce} from "@/utils/debounce";
import {onBeforeWindowUnload} from '@/utils/beforeWindowUnload';
import {GameType, getGameTypeByRouteName, getGameTypeStrByGameType, getRouteNameByGameType} from "../../gameCommon";

// 게임 설정 모달
export default {
  name: "Room",
  components: {
    MaintenanceNotice: () => import(/* webpackChunkName: "maintenance-notice-v2" */ '@/components/MaintenanceNotice.vue'),
    CommentNotice: () => import(/* webpackChunkName: "comment-notice-v2" */ '@/components/CommentNotice.vue'),
    GameTable: () => import(/* webpackChunkName: "game-table-v2" */ '@/components/Game/Table.vue'),
    GameEmotionModal: () => import(/* webpackChunkName: "game-chat-v2" */ '@/components/Game/Modal/Chat.vue'),
    PlayerRecordModal: () => import(/* webpackChunkName: "player-record-modal-v2" */ '@/components/Game/Modal/PlayerRecord.vue'),
    TournamentInfoLayerModal: () => import(/* webpackChunkName: "game-tournament-info-layer-v2" */ '@/components/Game/Modal/TournamentInfoLayer.vue'),
    GamePlayerTurnActions: () => import(/* webpackChunkName: "game-player-turn-actions-v2" */ '@/components/Game/PlayerTurnActions.vue'),
    LeaveRoomReservationButton: () => import(/* webpackChunkName: "leave-room-reservation-v2" */ '@/components/LeaveRoomReservation.vue'),
    GameConfigModal: () => import(/* webpackChunkName: "game-config-modal-v2" */ '@/components/GameConfigModal.vue'),
    NetworkErrorModalView: () => import(/* webpackChunkName: "network-error-modal-view-v2" */ '@/views/v2/NetworkError.vue'),
    GameObserverCount: () => import(/* webpackChunkName: "game-observer-count-v2" */ '@/components/Game/ObserverCount.vue'),
  },
  props: ['referenceData'],
  data() {
    return {
      networkChecker: null,
      networkErrorModal: false,
      actionMenu: false,
      isSoundOn: false,
      debug: false,
      isLoading: true,
      gameType: GameType.Relay,
      roomCode: 0,
    }
  },
  computed: {
    ...mapGetters('game', [
      'useDebug',
      'heartbeat',
      'remainingTurnTime',
      'roomInfo',
      'gameInfo',
      'tournamentInfo',
      'playersInfo',
      'myInfo',
      'canPlayGame',
      'isValidShowdown',
      'lastRoomState',
      'roomStateName',
      'gameStatusName',
      'isPlaying',
      'isPaused',
      'playerSocketIssues',
    ]),
    ...mapGetters('emotion', ['emotions']),
    ...mapGetters('config', ['sound']),
    ...mapGetters(['appHiddenTimeout', 'appVisibility', 'timestampOnHidden', 'isOnline', 'isConnected', 'networkUnavailable', 'networkErrorCount']),
    hasNetworkError() {
      return !this.isOnline || !this.isConnected;
    },
    players() {
      return this.playersInfo;
    },
    isRelayGame(){
      return this.gameType === GameType.Relay
    },
    isTournamentGame(){
      return this.gameType === GameType.Tournament
    },
  },
  watch: {
    playerSocketIssues: {
      immediate: true,
      handler: 'onPlayerSocketIssue'
    },
    appVisibility: {
      immediate: true,
      handler(state, _state) {
        const currentTime = new Date().getTime();

        if (currentTime - this.timestampOnHidden < this.appHiddenTimeout) {
          return;
        }

        state === 'visible' && _state === 'hidden' && this.refresh();
      }
    },
    networkUnavailable() {
      this.networkErrorModal = this.networkUnavailable;
    },
    myInfo: {
      deep: true,
      immediate: true,
      handler() {
        this.checkState();
      }
    },
  },
  methods: {
    ...mapMutations('game', {
      __updateHeartbeat: 'UPDATE_HEARTBEAT',
      __setTWaitTurn: 'SET_T_WAIT_TURN',
      __setRoomInfo: 'SET_ROOM_INFO',
      __setGameInfo: 'SET_GAME_INFO',
      __setPlayersInfo: 'SET_PLAYERS_INFO',
      __setMyInfo: 'SET_MY_INFO',
      __setLastRoomState: 'SET_LAST_ROOM_STATE',
      __setTournamentInfo: 'SET_TOURNAMENT_INFO',
    }),
    ...mapActions('config', ['toggleSound']),
    onConnect() {
      this.$store.commit('SET_IS_ONLINE', window.navigator.onLine);
      this.$store.commit('RESET_NETWORK_ERROR_COUNT');

      !this.$socket.connected && this.$socket.connect();
      this.$forceUpdate();

      this.$nextTick(() => {
        this.checkNetworkStatus();
      });
    },
    /**
     * watch handler
     */
    onPlayerSocketIssue() {
      if (this.playerSocketIssues.length === 0) {
        return;
      }

      const latestPlayer = this.playerSocketIssues[this.playerSocketIssues - 1] || null;

      if (latestPlayer && this.myInfo.sn === latestPlayer.sn) {
        console.log('socket error check1')
        this.$store.commit('SET_IS_ONLINE', false);
        this.$store.commit('SET_IS_CONNECTED', false);
        this.$store.commit('game/REMOVE_PLAYER_SOCKET_ISSUE', latestPlayer.sn);
      } else {
        const player = this.playerSocketIssues.find(sn => this.myInfo.sn === sn);

        if (!player) {
          return;
        }

        if (this.myInfo.sn === player.sn) {
          console.log('socket error check2')
          this.$store.commit('SET_IS_ONLINE', false);
          this.$store.commit('SET_IS_CONNECTED', false);
          this.$store.commit('game/REMOVE_PLAYER_SOCKET_ISSUE', player.sn);
        }
      }
    },
    onDisconnect() {
      this.$store.commit('SET_IS_ONLINE', window.navigator.onLine);
    },
    onSocketConnected() {
      this.$store.commit('SET_IS_CONNECTED', true);
      this.$store.commit('RESET_NETWORK_ERROR_COUNT');

      !this.$socket.connected && this.$socket.connect();
      this.$forceUpdate();

      this.$nextTick(() => {
        this.checkNetworkStatus();
      });
    },
    onSocketDisconnected() {
      this.$store.commit('SET_IS_CONNECTED', false);
    },
    checkNetworkStatus() {
      this.networkChecker = this.networkChecker || setInterval(() => {
        if (this.hasNetworkError) {
          clearInterval(this.networkChecker);
        }

        this.$store.commit('SET_IS_NETWORK_UNSTABLE', this.hasNetworkError);

        //연결끊김 디버깅
        if (this.hasNetworkError) {
          console.log(`networkUnavailable: ${this.networkUnavailable}`);
          console.log(`hasNetworkError: ${this.hasNetworkError}`);
          console.log(`networkErrorCount: ${this.networkErrorCount}`);
          console.log(`isOnline: ${this.isOnline}, isConnected:${this.isConnected}`);
          console.log(this.playerSocketIssues);
        }
      }, 1500);
    },
    onCloseNetworkErrorModal() {
      this.networkErrorModal = false;
    },
    async checkState() {
      await this.$store.dispatch('auth_check');
    },
    spreadPlayerEmotion(emotion) {
      gameSock.poker_emotion(
        this.$socket,
        this.roomInfo.sn,
        {...emotion, sentAt: Date.now()}
      );
    },
    onPlayerEmotion(emotion) {
      const {sn: playerId, type, content} = emotion.data
      this.$store.dispatch('emotion/setEmotion', {playerId, emotion: {type, content}});
    },
    /**
     * Enter
     * @returns {Promise<void>}
     */
    async enterGame() {
      try {
        gameSock.poker_enter(this.$socket, {roomCode: this.$route.query.roomCode});

        const ret = await gameSock.poker_get_gameinfo(
          this.$socket,
          {roomCode: this.$route.query.roomCode}
        );
        this.__setGameInfo(ret.gameinfo);
      } catch (e) {
        this.onEnterRet(e);
      }
    },
    onEnterRet(body) {
      if (body.ret === 0) {
        return;
      }

      const msg = this.errorProc(`enter`, body);

      this.$swal.fire({
        title: msg,
        icon: "error",
      }).then(() => {
        //다른방 접속중일경우 예외처리
        if ( [-1, -10, -11].includes(body.ret) && body.data?.roomCode > 0) {
          const uri = body.data?.uri ?? window.location.host;
          const roomCode = body.data?.roomCode;
          const routeName = getRouteNameByGameType(body.data?.game_type);
          window.location.href = `${uri}/${routeName}?roomCode=${roomCode}`;
        } else {
          this.redirectToLobby();
        }
      });
    },
    onEnterRetByServ(body) {
      if (body.ret === 0) {
        return;
      }

      this.isTimerPause = true;

      const msg = this.errorProc(`enter`, body);

      if( msg ){

        this.$swal.fire({
          title: msg,
          icon: "error",
        }).then(() => {
          this.redirectToLobby();
        });

      }else{
        this.redirectToLobby();
      }
    },
    onPokerGameData(data) {
      const {roominfo, playersinfo} = data.common;

      this.__setRoomInfo(roominfo);

      this.__setTWaitTurn(roominfo.tRemainTurn + Date.now());

      this.__setPlayersInfo(playersinfo);

      this.__setMyInfo(data.mydata);

      if( this.isTournamentGame ){
        this.__setTournamentInfo(data.tournamentInfo);
      }

      // in Player
      this.EventBus.$emit(`PokerLastAction`, roominfo.lastActionInfo);

      if (this.canPlayGame && this.isValidShowdown) {
        this.EventBus.$emit(`onShowdownStart`);
      }

      this.__setLastRoomState(roominfo.state);
    },
    /**
     * in CardDealer
     */
    onCardDist() {
      this.$nextTick(() => {
        this.EventBus.$emit('card-dist');
      });
    },
    /**
     * kick
     * @returns {Promise<void>}
     */
    leaveGame: debounce(async function () {
      try {
        if (!this.$route.query.roomCode) {
          this.redirectToLobby();
          return;
        }

        await gameSock.poker_out(this.$socket, Number(this.$route.query.roomCode));
        this.redirectToLobby();
      } catch (body) {
        const msg = this.errorProc(`out`, body);

        this.$swal.fire({
          title: msg,
          icon: "warning",
        }).then(() => {
          if(body.ret === -1){
            this.redirectToLobby();
          }
        })
      }
    }, 500, {leading: true}),
    /**
     * 나가기 예약에 대한 실처리 로직
     * @param roomCode
     * @param userSN
     */
    onPerformLeaveRoomReservation({roomCode, userSN}) {
      if (!(roomCode === this.roomInfo.sn && userSN === this.myInfo.sn)) {
        return;
      }

      this.$store.commit('user/SET_IS_GAME_EXIT_RESERVED', false);
      this.redirectToLobby();
      // this.leaveGame();
    },
    onDisconnected(body) {
      this.$swal.fire({
        title: body.reason,
        icon: "error",
      }).then(() => {
        this.redirectToLobby();
      });

      //alert(body.reason);
      // window.location.href = '/';
    },
    onLogout(){
      localStorage.setItem(`loginToken`, undefined);
      this.$store.state.loginToken = '';

      if (this.$socket.connected) {
        this.$socket.close();
        this.$socket.destroy();
      }
    },
    onSocketDuplicated() {
      // alert('다른 브라우저에서 로그인했습니다');

      //로그아웃 처리
      this.onLogout();

      //경고창 보여주기
      this.$swal.fire({
        title: '다른 브라우저에서 로그인했습니다',
        icon: "error",
      }).then(() => {
        clearInterval(this.networkChecker);
        this.releaseBeforeWindowUnload();
        if( !window.opener ){
          this.$router.replace({name: 'login'});
        }
      }).finally(() =>{
        window.close();
      });
    },
    onSocketCut() {
      //로그아웃처리
      this.onLogout();

      //경고창
      this.$swal.fire({
        title: '서버에 장애가 발생하여 접속이 종료됩니다. 재접속 해주시기 바랍니다.',
        icon: "error",
      }).then(() => {
        this.redirectToLobby();
      })
    },
    initEventBus() {
      this.EventBus.$on(`socket-duplicated`, this.onSocketDuplicated);
      this.EventBus.$on(`socket-cut`, this.onSocketCut)

      this.EventBus.$on(`poker-disconnected`, this.onDisconnected);
      this.EventBus.$on(`poker-gamedata`, this.onPokerGameData);
      this.EventBus.$on(`poker-card-dist`, this.onCardDist);
      this.EventBus.$on(`poker-enter-ret`, this.onEnterRet);
      this.EventBus.$on(`poker-enter-ret-by-serv`, this.onEnterRetByServ);
      this.EventBus.$on(`poker-heartbeat`, this.__updateHeartbeat);
      this.EventBus.$on('poker-player-emotion', this.onPlayerEmotion)
      this.EventBus.$on(`poker-leave-room-reservation-ret`, this.onPerformLeaveRoomReservation);
    },
    goLobby({dpType, dpRowType}){
      this.$router.replace({name:'lobby', query:{dp: dpType, dpRow: dpRowType}});
    },
    redirectToLobby() {
      this.goLobby({dpType: getGameTypeStrByGameType(this.gameType), dpRowType: 'all'});
    },
    refresh() {
      this.releaseBeforeWindowUnload();
      window.location.reload();
    },
    beforeWindowUnload(event) {
      event.returnValue = true;
    },
    releaseBeforeWindowUnload() {
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
    },
    async loadRoomCssModule(){
      if( this.isRelayGame ){
        import(/* webpackChunkName: "room-game-v2" */ '../../assets/v2/css/holdum.css');
      }else if( this.isTournamentGame ){
        import(/* webpackChunkName: "room-tournament-v2" */ '../../assets/v2/css/tournament.css');
      }
    },
    validateRoomGameType(){
      return this.gameInfo.type === this.gameType;
    }
  },
  async created() {
    this.roomCode = Number(this.$route.query.roomCode);
    this.gameType = getGameTypeByRouteName(this.$route.name);
    await this.loadRoomCssModule();

    window.addEventListener('beforeunload', onBeforeWindowUnload);
    window.addEventListener('online', this.onConnect);
    window.addEventListener('offline', this.onDisconnect);
    this.initEventBus();
    await this.enterGame().then(()=>{
      // console.log(this.gameInfo);
      // console.log(this.roomInfo);
      //접속후, 접속 라우트와 backend에서 가져온 게임타입이 동일한지 확인
      if( !this.validateRoomGameType() ){
        this.$swal.fire({
          title: '존재하지 않는 방입니다',
          icon: "error",
        }).then(() => {
          this.redirectToLobby();
        })
      }
    });
  },
  mounted() {
    this.$store.commit('SET_IS_CONNECTED', this.$socket.connected);
    this.$store.commit('SET_IS_ONLINE', window.navigator.onLine);
    this.$store.commit('RESET_NETWORK_ERROR_COUNT');

    this.$socket.removeListener(`connect`, this.onSocketConnected);
    this.$socket.removeListener(`disconnect`, this.onSocketDisconnected);
    this.$socket.on(`connect`, this.onSocketConnected);
    this.$socket.on(`disconnect`, this.onSocketDisconnected);

    this.checkNetworkStatus();
  },
  beforeDestroy() {
    this.releaseBeforeWindowUnload();
  }
}
</script>

<template>
  <div id="wrap" :class="{
    wrap_holdum_room: isRelayGame,
    wrap_tournament_room: isTournamentGame
  }">
    <!--상단 메뉴-->
    <div class="header_game">
      <div class="area_left">
        <div>
          <button
            type="button"
            class="btn_d btn_volume"
            :class="{on: sound, off: sound === 0}"
            @click.prevent.stop="toggleSound">
          </button>
        </div>

        <GameObserverCount/>
      </div>
      <div class="area_right">
        <div class="me-4">
          <button type="button" data-bs-toggle="modal" data-bs-target="#game_option">
            <img src="@/assets/v2/svg/settings.svg" alt="게임 설정">
          </button>
        </div>
        <div class="leave-room-reservation__wrap">
          <LeaveRoomReservationButton @leave="leaveGame"/>
        </div>
      </div>
      <MaintenanceNotice />
      <CommentNotice />
    </div>
    <!--//상단 메뉴-->

    <div class="container_game">
      <div :class="{
        wrap_game_holdum: isRelayGame,
        wrap_game_tournament: isTournamentGame,
      }">
        <GameTable/>

        <div class="footer_game">
          <GamePlayerTurnActions/>
        </div>

        <GameEmotionModal @emotion="spreadPlayerEmotion"/>

        <TournamentInfoLayerModal v-if="isTournamentGame" layerType="room" :tournamentInfo="tournamentInfo" :userSn="Number(myInfo.sn)"/>
      </div>
    </div>

    <GameConfigModal/>

    <PlayerRecordModal />

    <AppModal
      class="network-error-modal"
      :permanent="true"
      v-model="networkErrorModal"
      @close="onCloseNetworkErrorModal"
    >
      <NetworkErrorModalView/>

      <template #custom-action>
        <a type="button" class="refresh-action" @click.prevent.stop="refresh">새로고침</a>
      </template>
    </AppModal>
  </div>
</template>

<style lang="scss">
button.btn_volume {
  transition: transform 50ms ease-in-out;
}

button.btn_volume:active {
  transform-origin: center center;
  transform: scale(0.94);
}

.leave-room-reservation__wrap {
  position: relative;;
}
</style>